import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomepageLayout from './layouts/HomepageLayout';
import DefaultLayout from './layouts/DefaultLayout';

import AppContext from './commonComponents/Context/AppContext';
import { useState } from 'react';
import ComingSoonLayout from './layouts/ComingSoonLayout';

function App() {
  const [sidebarLayout, setSidebarLayout] = useState('rightsidebar')
  const stagging = 'enexio';
  const live = '/'

  return (
    <AppContext.Provider value={{ sidebarLayout, setSidebarLayout }}>
      <>
        <Router basename={live}>
          <Routes>
            <Route path="/" name="Home" element={<HomepageLayout />} />
            <Route path="/*" name="default" element={<DefaultLayout />} />
            <Route path="/ComingSoon" name="ComingSoon" element={<ComingSoonLayout />} />
          </Routes>
        </Router>
      </>
    </AppContext.Provider>
  );
}

export default App;
